<script>
  import { onMount } from 'svelte';

  export let componentData=[]
  export let dividerAbove = false;
  export let dividerBelow = false

  const BASE_URL = import.meta.env.PUBLIC_BASE_URL;
  const BASE_SELF_URL = import.meta.env.PUBLIC_BASE_SELF_URL;

  let isOnMobile = false;

  onMount(() => {
    isOnMobile = isMobile();
  });
  
  let facilityItems = [];
  if(componentData){
    facilityItems = componentData.map((item, index) => {
      let src = item.attributes && item.attributes.Image && item.attributes.Image.data? BASE_URL + item.attributes.Image.data.attributes.url : 'No Image URL';

      console.log('src item.attributes.ImageMobile', item.attributes.ImageMobile)
      let imgSrcMobile = item && item.attributes &&
      item.attributes.ImageMobile && item.attributes.ImageMobile.data &&
      item.attributes.ImageMobile.data.length > 0 &&
      item.attributes.ImageMobile.data[0].attributes &&
      item.attributes.ImageMobile.data[0].attributes.url ?
              BASE_URL + item.attributes.ImageMobile.data[0].attributes.url :
              'No Image URL';      //let imgSrcMobile = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSsa76bIbqiQJL_CQcll2GrAylXe4rYMsnM_j0eIfDcIQ&s';

      return {
        id : index + 1,
        type: src.endsWith('.mp4') ? 'video' : 'photo',
        title: item.attributes && item.attributes.Title ? item.attributes.Title : 'No Title',
        src: src,
        imgSrcMobile: imgSrcMobile,
        logoSrc: item.attributes && item.attributes.SVG_Logo && item.attributes.SVG_Logo.data? BASE_URL + item.attributes.SVG_Logo.data.attributes.url : 'No Image URL',
        logoSrcHover: item.attributes && item.attributes.SVG_Hover_Logo && item.attributes.SVG_Hover_Logo.data? BASE_URL + item.attributes.SVG_Hover_Logo.data.attributes.url : 'No Image URL',
        logoAlt: 'blockLogo',
        btnLink: item.attributes && item.attributes.slug ? item.attributes.slug : 'No slug',
        btnText: item.attributes && item.attributes.Button_Text ? item.attributes.Button_Text : '',
      }
    });
  }

  function isMobile() {
    return window.matchMedia('(max-width: 750px)').matches;
  }

  let videoElement;
  
  function playVideo() {
    videoElement.play();
  }
  
  function pauseVideo() {
    videoElement.pause();
  }
</script>

<style lang="scss">
  .facility {
    padding: 20px 0;
  }

  .facility__container {
    position: relative;
    overflow: hidden;
  }

  @media screen and (min-width: 1024px) {
    .scroll-snap:before {
      content: '';
      position: relative;
      display: block;
      height: 10px;
      margin-bottom: -10px;
      scroll-snap-align: start;
      scroll-margin-top: var(--header-height, 70px);
      pointer-events: none;
    }
  }

  .facility__sticky {
    height: calc(100vh - var(--header-height));
  }

  .facility__item {
    @media screen and (max-width: 1023px) {
      position: relative;
    }
  }

  .facility__item__inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    height: 100%;

    @media screen and (min-width: 1024px) {

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--color-primary);
        z-index: -1;
        transition: transform .5s ease, z-index .5s ease;
        transform-origin: bottom center;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        background: var(--color-secondary);
        z-index: 2;
        transition: transform .5s ease;
        transform-origin: bottom center;
      }

      .facility__item:first-child &:after { content: none; }
    }
  }

  .facility__item__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    
    @media screen and (min-width: 1024px) {
      opacity: 0;
      transition: opacity 0.5s ease;
    }
    
    img, video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .facility__item__logos {
    position: absolute;
    top: 30px;
    left: 80px;
    right: 80px;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1024px) {
      top: 20px;
      left: 60px;
      right: 60px;
      bottom: 20px;
    }
  }

  .facility__item__logo {
    transition: opacity 0.5s ease;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  

  @media screen and (min-width: 1024px) {
    .facility__item__logo--hover {
      opacity: 0;
    }

    .facility__item:hover {
      .facility__item__logo--default { opacity: 0; }
      .facility__item__logo--hover { opacity: 1; }
      .facility__item__bg {
        z-index: 1;
        opacity: 1;
        transform: none;
      }

      .facility__item__inner:before {
        transform: scaleY(0);
        z-index: 2;
      }
      .facility__item__inner:after {
        transform: scaleY(0);
      }

      & + .facility__item .facility__item__inner:after {
        transform: scaleY(0);
      }

      .btn--outline-alt {
        --text: var(--color-primary);
        --border: var(--color-primary);
        --text-hover: var(--color-secondary);
        --border-hover: var(--color-secondary);
      }
    }
  }

  .facility__item__button {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: 30px 20px;
  }

  @media screen and (max-width: 1023px) {
    .facility__item__logo { transition: none; }
    .facility__item__logo--default { opacity: 0; }
    .facility__item__logo--hover,
    .facility__item__bg { transform: none; }
    .facility__item__button { display: none; }
  }
</style>

<section class="facility"
  class:divider-above={dividerAbove}
  class:divider-below={dividerBelow}>
  <div class="wrapper">
    <div class="scroll-snap"></div>
    <div class="facility__container">
      <div class="facility__sticky grid grid--4 grid--1-tablet no-gap">
        {#each facilityItems as item}
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div 
            class="facility__item" 
            on:mouseenter={()=>item.type==='video' && playVideo()} 
            on:mouseleave={()=>item.type==='video' && pauseVideo()}
          >

            <div class="facility__item__bg">
              {#if item.type === 'photo' || (item.type === 'video' && isOnMobile)}
                <div class="hero__image">
                  <picture>
                    {#if item.imgSrcMobile && isOnMobile}
                      <!-- Display srcMobile for viewports up to 750px -->
                      <source media="(max-width: 750px)" srcset="{item.imgSrcMobile}">
                    {/if}
                    <!-- Fallback to imgSrc for larger viewports or non-video files-->
                    <img width="1920" height="1080" src="{item.src}" alt="{item.title}" loading="lazy">
                  </picture>
                </div>
              {:else if item.type === 'video'}
                <div class="hero__image">
                  <video width="1920" height="1080" bind:this={videoElement} muted autoplay>
                    <!-- Display the video -->
                    <source src={item.src} type="video/mp4">
                    Your browser does not support the video tag.
                  </video>
                </div>
              {/if}
            </div>

            <a href={item.btnLink} class="facility__item__inner">
              <div class="facility__item__logos">
                <img class="facility__item__logo facility__item__logo--default" src={item.logoSrc} alt={item.logoAlt} loading="lazy">
                <img class="facility__item__logo facility__item__logo--hover" src={item.logoSrcHover} alt={item.logoAlt} loading="lazy">
              </div>

              <div class="facility__item__button">
                <span class="btn btn--outline-alt">{item.btnText}</span>
              </div>
            </a>
          </div>
        {/each}
      </div>
    </div>
  </div>
</section>
